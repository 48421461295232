@import "@/assets/scss/var.scss";
.forget {
//     .btn-grp {
//         display: flex;
//         flex-flow: column;
//     }
//     &-inner {
//         .title {
//             font-size: 20px;
//             margin-bottom: 20px;
//             text-align: center;
//         }
//     }
//     &-agreement {
//         margin-bottom: 10px;
//         /deep/.el-checkbox__label {
//             color: initial;
//         }
//     }
//     &-operate {
//         display: flex;
//         justify-content: space-between;
//         margin: 15px 0;
//         > a {
//             font-size: 14px;
//             color: $color-primary;
//         }
//     }
    &-success {
        text-align: center;
        margin-top: 50px;
        .icon {
            width: 60px;
            height: 60px;
        }
        > div,
        .el-button {
            margin-top: 15px;
        }
        .el-button {
            width: 100%;
        }
        .stat {
            font-size: 16px;
            font-weight: bold;
        }
    }
//     .el-form {
//         // padding: 0 50px;
//     }
}
